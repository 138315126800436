<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Blog - kategorie - seřadit</strong>
      </div>
      <action-tools :routerOptions="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form class="login-form" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-tree
            @drop="handleDrop"
            draggable
            :treeData="treeData"
            class="draggable-tree"
          >
          </a-tree>
          <a-empty v-if="treeData.length === 0" />
        </a-form-item>

        <div class="form-actions">
          <a-button
            :disabled="loading"
            type="primary"
            htmlType="submit"
          >Seřadit</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      form: this.$form.createForm(this),
      treeData: [],
      loading: false,
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/blog/category/add',
          title: 'Přidat',
        },
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/blog/category',
          title: 'Seznam',
        },
      ],
    }
  },
  computed: {
    blogCategories: function () {
      return this.$store.getters['blogCategory/currentLanguage']('')
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.loading = true
      this.$store.dispatch('blogCategory/putSort', this.treeData.map(x => { return { bcy_id: x.key } }))
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    toTreeNodeData(data) {
      return data.map(x => {
        return {
          key: x.bcy_id,
          title: '#' + x.bcy_id + ' | ' + (x.languages[0] !== undefined ? x.languages[0].pivot.bce_name : 'není nenastaveno'),
          selectable: false,
        }
      })
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('blogCategory/getList')
        .catch(() => {})
        .finally(() => {
          this.treeData = this.toTreeNodeData(this.blogCategories)
          this.loading = false
        })
    },
    loop(data, key, callback) {
      return data.forEach((item, index, arr) => {
        if (item.key === key) {
          return callback(item, index, arr)
        }
      })
    },
    handleDrop(info) {
      if (info.dropToGap) {
        const dropPos = info.node.pos.split('-')
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
        let dragObj, ar, i
        this.loop(this.treeData, info.dragNode.eventKey, (item, index, arr) => {
          arr.splice(index, 1)
          dragObj = item
        })
        this.loop(this.treeData, info.node.eventKey, (item, index, arr) => {
          ar = arr
          i = index
        })
        dropPosition === -1 ? ar.splice(i, 0, dragObj) : ar.splice(i + 1, 0, dragObj)
      }
    },
  },
  created() {
    if (this.treeData.length === 0 && this.blogCategories.length !== 0) {
      this.treeData = this.toTreeNodeData(this.blogCategories)
    }
    if (this.blogCategories.length === 0 && this.$store.getters['language/active'] !== null) {
      this.handleRefresh()
    }
    this.$store.watch(
      (state, getters) => getters['language/active'], () => {
        if (this.$store.getters['language/active'] !== null && this.blogCategories.length === 0) {
          this.handleRefresh()
        }
      },
    )
  },
  watch: {
    blogCategories() {
      this.treeData = this.toTreeNodeData(this.blogCategories)
    },
  },
}
</script>

<style lang="scss" module scoped>

</style>
